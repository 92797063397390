@charset 'utf-8'
@use "sass:color"

@font-face 
    src: url('fonts/nexa/Thin.woff2') format('woff2'), url('fonts/nexa/Thin.woff') format('woff')
    font-family: 'Nexa'
    font-weight: 100

@font-face 
    src: url('fonts/nexa/Light.woff2') format('woff2'), url('fonts/nexa/Light.woff') format('woff')
    font-family: 'Nexa'
    font-weight: 200

@font-face
    src: url('fonts/nexa/Book.woff2') format('woff2'), url('fonts/nexa/Book.woff') format('woff')
    font-family: "Nexa"
    font-weight: 300

@font-face
    src: url('fonts/nexa/Heavy.woff2') format('woff2'), url('fonts/nexa/Heavy.woff') format('woff')
    font-family: "Nexa"
    font-weight: 600

$darkheader : #FFFFFF
$lightheader: #5D6162
$darktext   : #8E9192
$lighttext  : #74797A
$orange     : #F15A2B
$blue       : #0DBCD4
$green      : #CEDD37
$red        : #E51F1F
$yellow     : #E5BF1F
$darkbg     : #1D1E1E
$lightbg    : #E6E2DE
$darkbg2    : #222323
$buttonbg   : #333333

@function strip-unit($value)
  @return $value / ($value * 0 + 1)

@function fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size)
  $u1: unit($min-vw)
  $u2: unit($max-vw)
  $u3: unit($min-font-size)
  $u4: unit($max-font-size)
  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4
    @return calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}))

$min_width: 320px
$max_width: 1600px

$big-header-font-size-max : 96px
$header-font-size-max     : 64px
$subheader-font-size-max  : 36px
$button-font-size-max     : 24px
$input-font-size-max      : 15px
$text-font-size-max       : 14px

$big-header-font-size-min : 48px
$header-font-size-min     : 32px
$subheader-font-size-min  : 18px
$button-font-size-min     : 12px
$input-font-size-min      : 10px
$text-font-size-min       : 9px

$big-header-font-size     : fluid-type($min_width, $max_width, $big-header-font-size-min, $big-header-font-size-max)
$header-font-size         : fluid-type($min_width, $max_width, $header-font-size-min, $header-font-size-max)
$subheader-font-size      : fluid-type($min_width, $max_width, $subheader-font-size-min, $subheader-font-size-max)
$button-font-size         : fluid-type($min_width, $max_width, $button-font-size-min, $button-font-size-max)
$input-font-size          : fluid-type($min_width, $max_width, $input-font-size-min, $input-font-size-max)
$text-font-size           : fluid-type($min_width, $max_width, $text-font-size-min, $text-font-size-max)
  
$font-type: Nexa
$heavy-weight   : 600
$book-weight    : 300
$light-weight   : 200
$thin-weight    : 100

.thin
  font-weight: $thin-weight
.heavy
  font-weight: $heavy-weight
.emphasis
  color: $green

*
  -webkit-box-sizing: border-box
  box-sizing: border-box
  &:after, &:before
    -webkit-box-sizing: border-box
    box-sizing: border-box

.clearfix
  &:before
    content: ''
    display: table

  &:after
    content: ''
    display: table
    clear: both

html
  height: 100%

body
  background-color: $darkbg
  font-family: $font-type
  font-size: $text-font-size
  height: 100%
  min-height: 50em
  padding: 3em 5em
  font-family: Nexa
  position: relative
  margin: 0

a:focus, button:focus,input:focus, textarea:focus
  outline: none

.header
  margin: 0.5em 0 0 0
  font-size: $header-font-size
  font-weight: $heavy-weight
  color: $darkheader

hr
  border: none
  background-color: $orange
  width: 16em
  height: 0.2em
  left: 5em
  position: absolute

form
  margin: 2em -2em
  padding: 2em
  max-width: 80em
  position: absolute
  bottom: 0
  font-weight: $thin-weight
  background-color: color.change($darkbg, $alpha: 0.9)
  border-radius: 2em
  svg.page-logo
    font-size: $big-header-font-size
    color: $green
  .subheader
    letter-spacing: 2px
    color: $green
    font-size: $button-font-size
    margin: 1em 0
    text-transform: uppercase
  .text
    letter-spacing: 1px
    color: $darktext
    font-size: $text-font-size
    margin: 1em 0
    text-transform: uppercase
  .question
    letter-spacing: 2px
    color: $green
    font-size: $button-font-size
    margin: 1em 0
    text-transform: uppercase
  .button
    margin: 1em 0
    line-height: 4em
    height: 4em
    text-align: center
    width: 20em
    cursor: pointer
    display: block
    background-color: transparent
    border: 0
    font-weight: $heavy-weight
    text-decoration: none
    letter-spacing: 2px
  a.button.inline, button.button.inline
    display: inline-block
  a.button.narrow, , button.button.narrow
    width: 5em
  a.button.wide, , button.button.wide
    width: 100%
  button.button-navigation
    position: relative
    top: 2px
  .button-navigation
    color: $green
    transition: all 0.3s
    position: relative
    span
      transition: all 0.3s
    &::before
      content: ''
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      height: 100%
      z-index: 1
      opacity: 0
      transition: all 0.3s
      border-top-width: 2px
      border-bottom-width: 2px
      border-top-style: solid
      border-bottom-style: solid
      border-top-color: $green
      border-bottom-color: $green
      transform: scale(0.1, 1)
    &:hover, &:focus
      span
        letter-spacing: 3px
      &::before
        opacity: 1
        transform: scale(1, 1)
    &::after
      content: ''
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      height: 100%
      z-index: 1
      transition: all 0.3s
      background-color: rgba(255, 255, 255, 0.1)
    &:hover::after, &:focus::after
      opacity: 0
      transform: scale(0.1, 1)
  .button-action
    color: $orange
    transition: all 0.5s
    position: relative
    letter-spacing: 2px
    &::before
      content: ''
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      z-index: 1
      background-color: rgba(255, 255, 255, 0.1)
      transition: all 0.3s
    &:hover::before,&:focus::before
      opacity: 0
      transform: scale(0.5, 0.5)
    &::after
      content: ''
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      z-index: 1
      opacity: 0
      transition: all 0.3s
      border: 2px solid $orange
      transform: scale(1.2, 1.2)
    &:hover::after, &:focus::after
      opacity: 1
      transform: scale(1, 1)

  .animated-wrapper
    font-size: $input-font-size
    width: 30em
    .input
      position: relative
      z-index: 1
      display: inline-block
      max-width: 40em
      width: calc(100% - 2em)
      vertical-align: top
      margin: 2.4em 0 1.2em 0
      svg
        position: absolute
        font-size: 1.5em
        margin: calc(0.5em + 1px) 0.3em
        color: $green
        transition: color 1s
        min-width: 1em
      input, textarea
        text-transform: uppercase
        position: relative
        display: block
        float: right
        border: none
        border-radius: 0
        background: $green
        color: $green
        font-weight: $thin-weight
        -webkit-appearance: none
        transition: color 1s 
        padding: 0.4em 0.25em
        padding-left: 1.6em
        width: 100%
        background: transparent
        font-size: 1.55em
        &:focus
          outline: none
      textarea
        height: 8em
        padding-left: 3em
        letter-spacing: 1px
        resize: none
        font-family: $font-type
        font-size: $text-font-size
    .input__label
      position: absolute
      display: inline-block
      float: right
      padding: 0 1em
      width: 40%
      width: 100%
      font-weight: $heavy-weight
      font-size: 70.25%
      text-align: left
      color: $green
      transition: color 1s 
      -webkit-font-smoothing: antialiased
      -moz-osx-font-smoothing: grayscale
      -webkit-touch-callout: none
      -webkit-user-select: none
      -khtml-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none
      pointer-events: none
      &::before, &::after
        background:  linear-gradient(to right, $orange 50%, $green 50%)
        background-size: 200% 100%
        background-position: right bottom
        content: ''
        position: absolute
        left: 0
        z-index: -1
        width: 100%
        height: 2px
        -webkit-transition: -webkit-transform 0.3s, 
        transition: transform 0.3s, background-position 1s ease   
      &::before
        top: 0
      &::after
        bottom: 0
      .input__label-content
        position: relative
        display: block
        padding: 1.6em 0
        padding-left: 2.4em
        width: 100%
        -webkit-transition: -webkit-transform 0.3s
        transition: transform 0.3s
    textarea + label.input__label
      display: block
      height: 100%
    .graphic
      position: absolute
      top: 0
      left: 0
      fill: none
    .input
      input, textarea
        &:focus + .input__label .input__label-content, .input--filled .input__label-content
          -webkit-transform: translate3d(0, -90%, 0)
          transform: translate3d(0, -90%, 0)
        &:focus + .input__label::before, .input--filled .input__label::before
          -webkit-transform: translate3d(0, -0.5em, 0)
          transform: translate3d(0, -0.5em, 0)
        &:focus + .input__label::after, .input--filled .input__label::after
          -webkit-transform: translate3d(0, 0.5em, 0)
          transform: translate3d(0, 0.5em, 0)
    .input.input--filled
      .input__label-content
          -webkit-transform: translate3d(0, -90%, 0)
          transform: translate3d(0, -90%, 0)
      .input__label::before
          -webkit-transform: translate3d(0, -0.5em, 0)
          transform: translate3d(0, -0.5em, 0)
      .input__label::after
          -webkit-transform: translate3d(0, 0.5em, 0)
          transform: translate3d(0, 0.5em, 0)
  .invalid
    .input
      svg
        color: $orange
      input
        color: $orange
    .input__label
      color: $orange
      &::before, &::after
        background-position: left bottom

  .animated-option-container, .animated-checkbox-container
    font-size: $button-font-size
    text-transform: uppercase
    input[type='radio'], input[type='checkbox']
        height: 0
        width: 0
        display: none
    input[type='radio'] + label, input[type='checkbox'] + label
      position: relative
      display: flex
      cursor: pointer
      margin: .6em 0
      align-items: center
      color: $darktext
      transition: color 250ms cubic-bezier(.4,.0,.23,1)
    input[type='radio'] + label > ins, input[type='checkbox'] + label > ins
      position: absolute
      display: block
      bottom: 0
      left: 2em
      height: 0
      width: 100%
      overflow: hidden
      text-decoration: none
      transition: height 300ms cubic-bezier(.4,.0,.23,1)
    input[type='radio'] + label > ins > i, input[type='checkbox'] + label > ins > i
      position: absolute
      bottom: 0
      font-style: normal
      color: $green
    input[type='radio'] + label > span, input[type='checkbox'] + label > span
      display: flex
      position: relative
      top: -0.2em
      justify-content: center
      align-items: center
      margin-right: 1em
      width: 1em
      height: 1em
      background: transparent
      border: 2px solid $darktext
      cursor: pointer
      transition: all 250ms cubic-bezier(.4,.0,.23,1)
    input[type='radio'] + label > span
      border-radius: 0.5em      
    input[type='radio'] + label:hover, input[type='radio']:focus + label, input[type='checkbox'] + label:hover, input[type='checkbox']:focus + label
      color: #fff
    input[type='radio'] + label:hover > span, input[type='radio']:focus + label > span, input[type='checkbox'] + label:hover > span, input[type='checkbox']:focus + label > span
      background: rgba(255,255,255,.1)
    input[type='radio']:checked + label > ins, input[type='checkbox']:checked + label > ins
        height: 100%
    input[type='radio']:checked + label > span, input[type='checkbox']:checked + label > span
      border: .5em solid $green
      animation: shrink-bounce 200ms cubic-bezier(.4,.0,.23,1)
    input[type='radio']:checked + label > span:before, input[type='checkbox']:checked + label > span:before
      content: ""
      position: absolute
      top: 0.1em
      left: -0.2em
      border-right: 3px solid transparent
      border-bottom: 3px solid transparent
      transform: rotate(45deg)
      transform-origin: 0% 100%
      animation: checkbox-check 125ms 250ms cubic-bezier(.4,.0,.23,1) forwards
    @keyframes shrink-bounce
      0%
        transform: scale(1)
      33%
        transform: scale(.85)
      100%
        transform: scale(1) 
    @keyframes checkbox-check
      0%
        width: 0
        height: 0
        border-color: $darkbg
        transform: translate3d(0,0,0) rotate(45deg)
      33%
        width: .2em
        height: 0
        transform: translate3d(0,0,0) rotate(45deg)
      100%
        width: .2em
        height: .5em
        border-color: $darkbg
        transform: translate3d(0,-.5em,0) rotate(45deg)

  .validations
    .validation-message
      font-size: $text-font-size
      font-weight: $thin-weight
      color: $orange
      text-transform: uppercase
      margin: 0 0 0.2em 0
      max-width: 40em

  // DROPZONE
  .file-dropzone
    width: 48em
    height: 24em
    text-align: center
    position: relative
    padding: 2em
    margin: 2em 0
    color: $darktext
    background-color: $buttonbg
    font-size: $input-font-size
    text-transform: uppercase
    outline: 2px dashed $green
    outline-offset: -1em
    -webkit-transition: outline-offset .3s ease-in-out, background-color .15s linear, outline-color .3s ease-in-out
    transition: outline-offset .3s ease-in-out, background-color .15s linear, outline-color .3s ease-in-out
    &::before
      top: 0
    &::after
      bottom: 0   
    svg.background
      color: $lightheader
      font-size: $big-header-font-size
      -webkit-transition: color .2 ease-in-out, font-size .2s linear
      transition: color .2s ease-in-out, font-size .2s linear
    svg.spinning
      animation: fa-spin 2s infinite linear
    #upload-document
      bottom: 1em
      position: absolute
      width: 92%
  .file-dropzone.is-dragover.invalid
    color: $orange
    outline-color: $orange
    transition: font-size .15s ease-in-out
    svg.background
      color: $orange
    &::before, &::after
      background-position: left bottom
  .file-dropzone.is-dragover
    outline-offset: -1.5em
    background-color: $darkbg2
    svg.background
      color: $green

// BUBBLES
#bubbles
    position: fixed
    right: 0
    top: 0
    height: 100%
    width: 360px
    z-index: -9999
    svg
        position: absolute
        bottom: 5em
        right: 5em
        fill: $darkbg
        z-index: -1
        .a
            fill: $darkbg2
    .bubble
        width: 60px
        height: 60px
        border-radius: 200px
        -moz-border-radius: 200px
        -webkit-border-radius: 200px
        position: absolute
        background-color: tranparent
    .orange
        border: 3px solid $orange
    .green
        border: 3px solid $green  
    .blue
        border: 3px solid $blue
    .x2
        left: 0%
        -webkit-transform: scale(0.6)
        -moz-transform: scale(0.6)
        transform: scale(0.6)
        opacity: 0.8
        -webkit-animation: moveclouds 32s linear infinite, sideWays 5s ease-in-out infinite alternate
        -moz-animation: moveclouds 32s linear infinite, sideWays 5s ease-in-out infinite alternate
        -o-animation: moveclouds 32s linear infinite, sideWays 5s ease-in-out infinite alternate
    .x3 
        left: 20%
        -webkit-transform: scale(0.8)
        -moz-transform: scale(0.8)
        transform: scale(0.8)
        opacity: 0.6
        -webkit-animation: moveclouds 24s linear infinite, sideWays 4s ease-in-out infinite alternate
        -moz-animation: moveclouds 24s linear infinite, sideWays 4s ease-in-out infinite alternate
        -o-animation: moveclouds 24s linear infinite, sideWays 4s ease-in-out infinite alternate
    .x4
        left: 50%
        -webkit-transform: scale(0.75)
        -moz-transform: scale(0.75)
        transform: scale(0.75)
        opacity: 0.7
        -webkit-animation: moveclouds 18s linear infinite, sideWays 2s ease-in-out infinite alternate
        -moz-animation: moveclouds 18s linear infinite, sideWays 2s ease-in-out infinite alternate
        -o-animation: moveclouds 18s linear infinite, sideWays 2s ease-in-out infinite alternate
    @-webkit-keyframes moveclouds
        0%
            top: 100%
        100%
            top: -10%
    @-webkit-keyframes sideWays
        0%
            margin-left:0px
        100%
            margin-left:50px
    @-moz-keyframes moveclouds  
        0%
            top: 100%
        100%
            top: -10%
    @-moz-keyframes sideWays
        0%
            margin-left:0px
        100%
            margin-left:50px
    @-o-keyframes moveclouds
        0%
            top: 100%
        100%
            top: -10%
    @-o-keyframes sideWays
        0%
            margin-left:0px
        100%
            margin-left:50px

.firepit 
  position: relative
  margin: 3em 0
.fire 
  position: relative
  width: 6em
  height: 6em
  .flame 
    position: absolute
    bottom: 0
    left: 0
    opacity: .9
    width: 100%
    height: 100%
    border-bottom-right-radius: 70%
    border-bottom-left-radius: 70%
    border-top-left-radius: 70%
    transform: rotate(-45deg)  skew(-10deg, -10deg)
    background-color: $red
    background-image: linear-gradient(top right, fade-out($red, .1), fade-out($orange, .3))
    z-index: 1
    
    animation-iteration-count: infinite
    animation-fill-mode: forwards
    animation-name: burn-left
    animation-duration: 300ms
    &:nth-of-type(2n)
      animation-name: burn-right
      animation-duration: 400ms
    &:nth-of-type(2) 
      height: 80%
      width: 80%
      left: 10%
      background-color: $orange
      background-image: linear-gradient(top right, fade-out($orange, .9), fade-out($yellow, .9))
      z-index: 2
    &:nth-of-type(3) 
      height: 50%
      width: 50%
      left: 25%
      background-color: $yellow
      background-image: linear-gradient(top right, $yellow, white)
      z-index: 3
    &:nth-of-type(4) 
      height: 30%
      width: 30%
      left: 35%
      background-color: $blue
      background-image: linear-gradient(top right, fade-out(white, .7), fade-out($blue,.1))
      opacity: 1
      z-index: 4
.spark 
  position: absolute
  bottom: 46%
  left: 15%
  width: 3px
  height: 3px
  background-color: fade-out($orange,.6)
  
  animation-name: fly-up-burnout-1
  animation-duration: 6s
  animation-timing-function: linear
  animation-iteration-count: infinite
  animation-delay: .4s
  
  &:nth-of-type(2n) 
    animation-name: fly-up-burnout-2
    animation-duration: 8.4s
    animation-delay: 1s
  &:nth-of-type(3n) 
    animation-delay: 1.9s
    animation-duration: 8s
    left: 55%
  &:nth-of-type(4n) 
    animation-delay: 2.6s
    animation-duration: 7s
    left: 88%
  &:nth-of-type(5n) 
    animation-delay: 1.4s
    animation-duration: 7s
    left: 22%
  &:nth-of-type(6n) 
    animation-delay: 3.2s
    left: 61%
  &:nth-of-type(7n) 
    animation-delay: .8s
    animation-duration: 5s
    left: 64%
  &:nth-of-type(8n) 
    animation-delay: 5s
    left: 19%
  &:nth-of-type(9n) 
    animation-delay: 4s
    animation-duration: 6.8s
    left: 22%
  &:nth-of-type(10n) 
    animation-delay: 5.2s
    left: 61%
  &:nth-of-type(11n) 
    animation-delay: 3.5s
    animation-duration: 8s
    left: 81%
  &:nth-of-type(12n) 
    animation-delay: 2s
    left: 85%

@keyframes burn-left 
  0%, 100%
    transform: rotate(-45deg)  skew(-10deg, -10deg) scale(1)
  30%, 60%
    transform: rotate(-44deg)  skew(-12deg, -12deg) scale(1.01)
@keyframes burn-right 
  0%, 100%
    transform: rotate(-45deg)  skew(-10deg, -10deg) scale(1)
  30%, 60%
    transform: rotate(-46deg)  skew(-6deg, -6deg) scale(1.01)
@keyframes fly-up-burnout-1 
  0% 
    transform: translate(0, 0)
    opacity: 1
  33%
    transform: translate(12px, -70px)
  66% 
    transform: translate(0, -140px)
    opacity: .6
  100% 
    transform: translate(6px, -200px)
    opacity: 0
@keyframes fly-up-burnout-2 
  0% 
    transform: translate(0, 0)
    opacity: 1
  50%
    transform: translate(-10px, -80px)
  80% 
    transform: translate(-4px, -140px)
    opacity: .6  
  100% 
    transform: translate(-6px, -160px)
    opacity: 0
  